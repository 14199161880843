import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: [
        {
            Title: () => <>Delta de l'Ebre</>,
            Details: () => (
                <>
                    <p>
                        The Natural Park of the Ebre Delta offers visitors a
                        wide range of itineraries to discover the most
                        interesting places in the Delta, on foot, by bicycle,
                        canoe, boat or horseback. You can discover its
                        birdwatching, mussel farms, kitesurfing, sailing and
                        amazing beaches.
                    </p>
                </>
            ),
            link:
                "https://www.deltaturistic.com/en/the-ebro-delta/tourism-the-ebro/",
        },
        {
            Title: () => <>Parc Natural dels Ports</>,
            Details: () => (
                <>
                    <p>
                        The Els Ports Natural Park is located between the
                        regions of Baix Ebre, Montsià and Terra Alta. It is
                        distinguished by its shapes, its two differing climates,
                        as well as its fauna, a highlight being the Spanish
                        ibex, to which the park acts as a reserve. Don't miss
                        the canyoning!
                    </p>
                </>
            ),
            link:
                "http://www.catalunya.com/els-ports-natural-park-17-17001-573555?language=en",
        },
        {
            Title: () => <>Tortosa</>,
            Details: () => (
                <>
                    <p>
                        Few cities without the status of provincial capital are
                        as important as Tortosa, which boasts 2000 years of
                        history. Every corner of the beautiful old town is
                        steeped in history. The Iberians chose to settle in what
                        is now the capital of the Baix Ebre region. In fact,
                        historians believe that it may had been the enigmatic
                        and important city of Hibera. If that was indeed the
                        case, the Ebre (Iber) river and the entire peninsula
                        were named after the city. As proof of its splendid
                        past, which saw Romans, Muslims, Jews and Christians
                        pass through, Tortosa boasts the impressive Suda Castle,
                        Saint Mary’s Cathedral and some elegant Modernist
                        buildings. It isn’t known as the city of three cultures
                        (Jewish, Arab and Christian) for nothing. Tortosa
                        experienced its golden age during the Renaissance (16th
                        century).
                    </p>
                </>
            ),
            link: "https://www.tortosaturisme.cat/en/",
        },
        {
            Title: () => <>L'Ampolla and L'Ametlla de Mar</>,
            Details: () => (
                <>
                    <p>
                        Can't miss these genuine fishing villages; enjoy their
                        beaches (platja Santa Llúcia, Morro de Gos, Cap Roig,
                        and Estany Tort), rent a boat an tour around the Delta
                        to Sant Carles de la Ràpita (where you can visit the
                        mussel farms), swim among tunas, and eat all the food!!
                    </p>
                </>
            ),
            link: "https://ampollaturisme.com/en/",
        },
        {
            Title: () => <>Castell de Miravet</>,
            Details: () => (
                <>
                    <p>
                        Twelfth century Templar fortress, built on a hill that
                        controls throughout the Ebre river that passes by his
                        feet and dominates the town of Miravet. In good
                        condition, is one of the great examples of its kind in
                        Western Europe. You can visit different rooms as the
                        walls and stables, cistern, kitchen, refectory,
                        warehouses or important Romanesque church. It is managed
                        by the Catalan Cultural Heritage of the Generalitat of
                        Catalonia.
                    </p>
                </>
            ),
            link:
                "http://www.turismemiravet.cat/proposta/el-castell-de-miravet/",
        },
        {
            Title: () => <>Centre Picasso</>,
            Details: () => (
                <>
                    <p>
                        In order to preserve and promote the legacy that the
                        painter from Malaga left in this municipality, the
                        Picasso Center exhibits facsimile reproductions of
                        almost 200 works done during the two stays the artist
                        made in this village and also other paintings painted in
                        Barcelona or in Paris, but inspired by the landscape of
                        Horta. The Picasso Center is located in the old
                        hospital, a Renaissance building that was built to
                        welcome patients and passers-by.
                    </p>
                </>
            ),
            link:
                "https://terresdelebre.travel/en/activities-what-to-do/culture/museums-and-visitor-centres/the-picasso-centre-horta-de-sant-joan",
        },
        {
            Title: () => <>Coves Meravelles</>,
            Details: () => (
                <>
                    <p>
                        Although its existence was already known by the people
                        of the town, it was not until 1968 when the Gracia
                        Center of Speleologists explored them for the first
                        time. The “Meravelles” are the most impressive of the
                        set of caves called “La Aumidiella”, with a wealth of
                        formations, showing stalactites, stalagmites, flags,
                        gours, and even the so-called “eccentrics”, which dare
                        to play with the law of gravity by growing inexplicably
                        in all possible directions.
                    </p>
                </>
            ),
            link:
                "https://terresdelebre.travel/en/activities-what-to-do/green-spaces/other-natural-spaces/coves-meravelles",
        },
        {
            Title: () => <>The Wine Cathedral</>,
            Details: () => (
                <>
                    <p>
                        The Pinell de Brai Catedral del Vi is the cooperative
                        building of the Modernist style of the early twentieth
                        century designed by Cèsar Martinell, a direct disciple
                        of Antoní Gaudí.
                    </p>
                </>
            ),
            link: "https://catedraldelvi.com/en/?v=907eba32d950",
        },
    ],
    [LANGUAGE.CAT]: [
        {
            Title: () => <>Delta de l'Ebre</>,
            Details: () => (
                <>
                    <p>
                        El Parc Natural del Delta de l’Ebre ofereix als seus
                        visitants una àmplia i variada gamma d’itineraris per
                        descobrir els indrets més interessants del Delta, sigui
                        a peu, en bici, canoa o a cavall. Podeu observar les
                        aus, anar a les muscleres, fer kitesurf, navegar i
                        disfrutar de les platges.
                    </p>
                </>
            ),
            link:
                "https://www.deltaturistic.com/el-delta-de-lebre/turisme-per-lebre/",
        },
        {
            Title: () => <>Parc Natural dels Ports</>,
            Details: () => (
                <>
                    <p>
                        El Parc Natural dels Ports està situat entre les
                        comarques del Baix Ebre, el Montsià i la Terra Alta. En
                        són característiques les seves formes abruptes i els
                        seus dos climes clarament diferenciats, amb una rica
                        biodiversitat. No us pergueu el barranquisme!{" "}
                    </p>
                </>
            ),
            link:
                "http://www.catalunya.com/parc-natural-dels-ports-17-17001-573555?language=ca",
        },
        {
            Title: () => <>Tortosa</>,
            Details: () => (
                <>
                    <p>
                        Hi ha poques ciutats petites que no siguen capital de
                        província amb la transcendència de Tortosa, amb una
                        història de 2.000 anys. A cada racó del seu riquíssim
                        nucli antic es respiren èpoques passades. Els ibers van
                        escollir la que avui és la capital de la comarca del
                        Baix Ebre per establir-se. De fet, els historiadors es
                        plantegen si va arribar a ser l’enigmàtica i important
                        ciutat Hibera. Si fos així, Tortosa hauria donat nom al
                        riu Ebre (Iber) i a tota la península. Com a proves del
                        seu esplendorós passat, per on van passar romans,
                        musulmans, jueus i cristians, Tortosa llueix
                        l’impressionant castell de la Suda, la catedral de Santa
                        Maria i elegants edificis modernistes. No en va se
                        l’anomena la ciutat de les tres cultures: cristiana,
                        jueva i àrab. Durant l’època del Renaixement (s. XVI)
                        Tortosa va viure el seu moment de màxima esplendor.
                    </p>
                </>
            ),
            link: "https://www.tortosaturisme.cat/",
        },
        {
            Title: () => <>L'Ampolla o L'Ametlla de Mar</>,
            Details: () => (
                <>
                    <p>
                        No us podeu perdre aquest dos pobles pesquers; les seves
                        platges (platja Santa Llúcia, Morro de Gos, Cap Roig i
                        Estany Tort), llogueu una barca per costejar el delta
                        fins a Sant Carles de la Ràpita (on podeu visitar les
                        muscleres), nadeu entre les tonyines i disfruteu del
                        menjar!!
                    </p>
                </>
            ),
            link: "https://ampollaturisme.com/",
        },
        {
            Title: () => <>Castell de Miravet</>,
            Details: () => (
                <>
                    <p>
                        Fortalesa templera del segle XII, construida sobre un
                        turó que controla tot el territori, el riu Ebre que
                        passa pels seus peus i domina tota la població de
                        Miravet. En bon estat de conservació, és un dels grans
                        exemples del seu estil a l’Europa occidental. Es poden
                        visitar les muralles i diferents estances com
                        cavallerisses, cisterna, cuina, refetor, magatzems o
                        l’important església romànica. Està gestionat per
                        l’Agència Catalana del Patrimoni Cultural de la
                        Generalitat de Catalunya.
                    </p>
                </>
            ),
            link:
                "http://www.turismemiravet.cat/proposta/el-castell-de-miravet/",
        },
        {
            Title: () => <>Centre Picasso</>,
            Details: () => (
                <>
                    <p>
                        Per preservar i promoure el llegat que el pintor
                        malagueny va deixar en aquest municipi, el Centre
                        Picasso exposa reproduccions facsímil de gairebé 200
                        obres realitzades en les dues estades que l'artista va
                        fer a la vila i també d'altres quadres pintats a
                        Barcelona o a París, però inspirats en el paisatge
                        d'Horta. El Centre Picasso està situat a l'antic
                        hospital, un edifici d'estil renaixentista que fou
                        construït per acollir malalts i transeünts.
                    </p>
                </>
            ),
            link:
                "https://terresdelebre.travel/que-fer/cultura/museus-i-centres-dinterpretacio/centre-picasso-dhorta-de-sant-joan",
        },
        {
            Title: () => <>Coves Meravelles</>,
            Details: () => (
                <>
                    <p>
                        Tot i que la seva existència ja era coneguda per la gent
                        del poble, no va ser fins el 1968 quan el Centre
                        d'Espeleologia de Gràcia les va explorar per primer cop.
                        Les Meravelles són les més impressionants del conjunt de
                        coves anomenades de l'Aumidiella. Amb una gran riquesa
                        en formacions, mostren estalactites, estalagmites,
                        banderes, gours, i fins i tot les anomenades
                        excèntriques, que s'atreveixen a jugar amb la llei de la
                        gravetat en créixer de manera inexplicable en totes les
                        direccions possibles.
                    </p>
                </>
            ),
            link:
                "https://terresdelebre.travel/que-fer/espais-naturals/altres-espais-naturals/coves-meravelles",
        },
        {
            Title: () => <>La Catedral del Vi</>,
            Details: () => (
                <>
                    <p>
                        La Catedral del Vi del Pinell de Brai és l’edifici
                        cooperativista d’estil modernista de principis del segle
                        XX dissenyat per Cèsar Martinell, un deixeble directe
                        d’Antoní Gaudí.
                    </p>
                </>
            ),
            link: "https://catedraldelvi.com/?v=e0ff4366bd30",
        },
    ],
}
